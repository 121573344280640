import React from "react"
import PropTypes from "prop-types"
import { ParallaxProvider } from "react-scroll-parallax"

import Cookies from "./cookies"
import Navbar from "./navbar"
import Footer from "./footer"
import "../styles/global.scss"

// Images
import TwistedShapeLeftDefault from "../assets/layout-twisted-shapes-left.inline.svg"
import TwistedShapeRightDefault from "../assets/layout-twisted-shapes-right.inline.svg"
import TwistedShapeMobile from "../assets/layout-twisted-shapes-mobile.inline.svg"

const MainStripe = ({
  stripeColor: background,
  stripeClass,
  stripeImg,
  stripeVid,
  hasMoreHeightStripe,
  hasLessHeightStripe,
  summerLanding,
}) => {
  let classes = ""
  if (hasMoreHeightStripe) classes += "has-more-height "
  if (summerLanding) classes += "summer-landing "
  else if (hasLessHeightStripe) classes += "has-less-height "
  return (
    <div
      style={{ background }}
      className={`main-stripe ${stripeClass} ${classes}`}
    >
      {stripeImg && (
        <img
          className="overlay-img"
          src={stripeImg.src}
          srcSet={stripeImg.srcSet}
          alt="Overlay"
        />
      )}
      {stripeVid && (
        <video
          className="overlay-vid"
          height="100%"
          width="100%"
          loop
          muted
          autoPlay
        >
          <source src={stripeVid} type="video/mp4" />
        </video>
      )}
    </div>
  )
}

const Layout = ({
  children,
  stripeClass,
  stripeColor,
  stripeImg,
  stripeVid,
  shapes_hue_rotate,
  pageName,
  hasMoreHeightStripe,
  hasLessHeightStripe,
  summerLandingHeight,
  hasFooterSimplified,
  isWider,
  TwistedShapesLeft,
  TwistedShapesRight,
  hasLightBackground,
  noMarginTopFooter,
  noHighlightedSection,
  TwistedShapesResponsive,
}) => {
  const isTransparent = stripeClass === "is-transparent"
  return (
    <ParallaxProvider>
      <Cookies />
      <div className={pageName}>
        <header>
          {isTransparent ? (
            <></>
          ) : (
            <div className="main-stripes-wrapper">
              <MainStripe
                stripeClass={stripeClass}
                stripeColor={stripeColor}
                stripeImg={stripeImg}
                stripeVid={stripeVid}
                summerLanding={summerLandingHeight}
                hasLessHeightStripe={hasLessHeightStripe}
                hasMoreHeightStripe={hasMoreHeightStripe}
              />
            </div>
          )}
          <Navbar hasLightBackground={hasLightBackground} />
        </header>
        <main>
          <div
            style={{ filter: `hue-rotate(${shapes_hue_rotate}deg)` }}
            className={`twisted-shapes ${isWider ? "is-wider" : ""}`}
          >
            {TwistedShapesLeft && TwistedShapesRight ? (
              <>
                <div className="twisted-shapes-left custom is-hidden-mobile">
                  <img src={TwistedShapesLeft} alt="Twisted Shapes" />
                </div>
                <div className="twisted-shapes-right custom is-hidden-mobile">
                  <img src={TwistedShapesRight} alt="Twisted Shapes" />
                </div>
              </>
            ) : (
              <>
                {TwistedShapesLeft !== null && (
                  <TwistedShapeLeftDefault className="twisted-shapes-left is-hidden-mobile" />
                )}
                {TwistedShapesRight !== null && (
                  <TwistedShapeRightDefault className="twisted-shapes-right is-hidden-mobile" />
                )}
              </>
            )}
            {TwistedShapesResponsive ? (
              <div className="twisted-shapes-mobile is-hidden-tablet">
                <img src={TwistedShapesResponsive} alt="Twisted Shapes" />
              </div>
            ) : (
              TwistedShapesResponsive !== null && (
                <TwistedShapeMobile className="twisted-shapes-mobile is-hidden-tablet" />
              )
            )}
          </div>
          {children}
        </main>
        <Footer
          isSimplified={hasFooterSimplified}
          noMarginTopFooter={noMarginTopFooter}
          noHighlightedSection={noHighlightedSection}
        />
      </div>
    </ParallaxProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  stripeClass: PropTypes.string,
  stripeColor: PropTypes.string,
  pageName: PropTypes.string.isRequired,
}

export default Layout
