import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import { Link, useIntl, changeLocale } from "gatsby-plugin-intl"
import { motion, AnimatePresence } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import { window } from "browser-monads"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Icons
import TwispayLogo from "../assets/icons/twispay-logo.svg"
import ArrowRightIcon from "../assets/icons/arrow.svg"
import PaymentGatewayIcon from "../assets/icons/navbar-payment-gateway.svg"
// import TransactionDashboardIcon from "../assets/icons/navbar-transaction-dashboard.svg"
import PaymentLinksIcon from "../assets/icons/navbar-payment-links.svg"
import EcommercePluginIcon from "../assets/icons/navbar-ecommerce-plugins.svg"
import LockIcon from "../assets/icons/lock.svg"
import TIcon from "../assets/icons/navbar-t.svg"
import DownloadIcon from "../assets/icons/logos-and-resources.svg"
import FAQIcon from "../assets/icons/Faq-colored.svg"
import EarthIcon from "../assets/icons/earth.svg"
import ArrowDown from "../assets/icons/arrow-down.svg"
import GatewayIcon from "../assets/icons/navbar-responsive-gateway.svg"
// import DashboardIcon from "../assets/icons/navbar-responsive-dashboard.svg"
import PaymentLinksResponsiveIcon from "../assets/icons/navbar-responsive-payment-link.svg"
import PluginIcon from "../assets/icons/navbar-responsive-plugin.svg"

// Styles
import "../styles/components/navbar.scss"

const DropdownCard = ({ children, width, minHeight, className }) => {
  const cardEl = useRef(null)
  const [leftValue, setLeftValue] = useState(undefined)
  const variants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  }

  useEffect(() => {
    if (cardEl.current) {
      const parentWidth = cardEl.current.parentNode.offsetWidth
      const cardWidth = cardEl.current.offsetWidth
      setLeftValue(parentWidth / 2 - cardWidth / 2)
    }
  }, [cardEl])
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      style={{ left: leftValue, width, minHeight }}
      ref={cardEl}
      className={`dropdown-card ${className}`}
    >
      {children}
    </motion.div>
  )
}

const TwistedShape = ({ color1, color2, top }) => {
  const icon = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
    },
  }
  return (
    <motion.svg
      width="202px"
      height="46px"
      viewBox="0 0 202 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="twisted-shape"
      style={{ top }}
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="50%"
          x2="100%"
          y2="50%"
          id="navbar-twisted-shape-gradient"
        >
          <stop stopColor={color1} stopOpacity="0" offset="0%"></stop>
          <stop stopColor={color2} offset="100%"></stop>
        </linearGradient>
      </defs>

      <motion.path
        d="M0.780391469,39.1866258 L130.491343,39.0816359 C133.843274,39.0816359 136.867859,37.070192 138.164072,33.9790303 L140.553152,28.2816359 L138.164072,22.5842416 C136.867859,19.4930799 133.843274,17.4816359 130.491343,17.4816359 L2.2585887,18.2122077"
        stroke="url(#navbar-twisted-shape-gradient)"
        transform="translate(70.666772, 28.334131) rotate(-5.000000) translate(-70.666772, -28.334131) "
        fill="none"
        variants={icon}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={{
          default: {
            duration: 0.6,
            ease: "easeOut",
          },
        }}
      />
      <motion.path
        d="M200.619166,3.1922303 L152.363219,4.30760872 C149.069633,4.30760872 146.08608,6.25049102 144.754031,9.26269168 L140.135105,19.7076087 L144.754031,30.1525258 C146.08608,33.1647264 149.069633,35.1076087 152.363219,35.1076087 L197.804804,34.1055928"
        stroke={color1}
        transform="translate(170.377135, 19.149920) rotate(-5.000000) translate(-170.377135, -19.149920) "
        fill="none"
        variants={icon}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={{
          default: {
            duration: 0.6,
            ease: "easeOut",
          },
        }}
      />
    </motion.svg>
  )
}

const CartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120.78"
    height="128.29"
    viewBox="0 0 120.78 128.29"
  >
    <circle cx="31.91" cy="56.27" r="22.47" fill="#e7eed7" />
    <g>
      <g>
        <path
          d="M24.06,58.44l95.67.28a1,1,0,0,1,1,1.05,1.07,1.07,0,0,1,0,.25L112.33,94.4a8.23,8.23,0,0,1-7.13,6.21L39,107.44Z"
          fill="#c5d4a0"
          fillRule="evenodd"
        />
        <path
          d="M21,41.64l20.64,69.85A4.55,4.55,0,0,0,46,114.76h60.86a3.9,3.9,0,0,1,3.9,3.76v-1.43c0,2.11-1.67,2-3.76,2H46a10.43,10.43,0,0,1-10-7.63L17.39,45A3.5,3.5,0,0,0,14,42.44h-7l.07-5h8.26A5.87,5.87,0,0,1,21,41.64Z"
          fill="#c5d4a0"
        />
        <circle cx="55.94" cy="119.49" r="8.8" fill="#60626e" />
        <circle cx="55.94" cy="119.49" r="3.5" fill="#fff" />
        <circle cx="101.93" cy="119.49" r="8.8" fill="#60626e" />
        <circle cx="101.93" cy="119.49" r="3.5" fill="#fff" />
        <path
          d="M29.06,62.44l10.87,38.45a3.15,3.15,0,0,0,3.36,2.28l63-6.55A3.15,3.15,0,0,0,109,94.26l8-31.82Z"
          fill="#a9c342"
          fillRule="evenodd"
        />
        <g>
          <path
            d="M43.89,68.6h4.66a2.34,2.34,0,0,1,2.34,2.33h0a2.34,2.34,0,0,1-2.34,2.34H43.89a2.34,2.34,0,0,1-2.34-2.34h0A2.34,2.34,0,0,1,43.89,68.6Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M47.39,77.93h4.66a2.33,2.33,0,0,1,2.33,2.33h0a2.33,2.33,0,0,1-2.33,2.34H47.39a2.34,2.34,0,0,1-2.34-2.34h0A2.34,2.34,0,0,1,47.39,77.93Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M68.38,87.26h4.67a2.34,2.34,0,0,1,2.33,2.34h0a2.34,2.34,0,0,1-2.33,2.33H68.38a2.33,2.33,0,0,1-2.33-2.33h0A2.33,2.33,0,0,1,68.38,87.26Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M85.88,87.26h4.66a2.33,2.33,0,0,1,2.33,2.34h0a2.33,2.33,0,0,1-2.33,2.33H85.88a2.34,2.34,0,0,1-2.34-2.33h0A2.34,2.34,0,0,1,85.88,87.26Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M60.22,68.6h4.66a2.33,2.33,0,0,1,2.33,2.33h0a2.33,2.33,0,0,1-2.33,2.34H60.22a2.34,2.34,0,0,1-2.34-2.34h0A2.34,2.34,0,0,1,60.22,68.6Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M64.88,77.93h4.67a2.34,2.34,0,0,1,2.33,2.33h0a2.34,2.34,0,0,1-2.33,2.34H64.88a2.33,2.33,0,0,1-2.33-2.34h0A2.33,2.33,0,0,1,64.88,77.93Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M52.05,87.26h4.67a2.34,2.34,0,0,1,2.33,2.34h0a2.34,2.34,0,0,1-2.33,2.33H52.05a2.33,2.33,0,0,1-2.33-2.33h0A2.33,2.33,0,0,1,52.05,87.26Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M76.55,68.6h4.66a2.33,2.33,0,0,1,2.33,2.33h0a2.33,2.33,0,0,1-2.33,2.34H76.55a2.34,2.34,0,0,1-2.34-2.34h0A2.34,2.34,0,0,1,76.55,68.6Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M80,77.93h4.67A2.33,2.33,0,0,1,87,80.26h0a2.33,2.33,0,0,1-2.33,2.34H80a2.34,2.34,0,0,1-2.33-2.34h0A2.34,2.34,0,0,1,80,77.93Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M97.54,77.93h4.66a2.34,2.34,0,0,1,2.34,2.33h0a2.34,2.34,0,0,1-2.34,2.34H97.54a2.33,2.33,0,0,1-2.33-2.34h0A2.33,2.33,0,0,1,97.54,77.93Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
          <path
            d="M92.87,68.6h4.67a2.33,2.33,0,0,1,2.33,2.33h0a2.33,2.33,0,0,1-2.33,2.34H92.87a2.34,2.34,0,0,1-2.33-2.34h0A2.34,2.34,0,0,1,92.87,68.6Z"
            fill="#ecf3df"
            fillRule="evenodd"
          />
        </g>
      </g>
      <path
        d="M4.66,37.44h3.5v10.5a3.5,3.5,0,0,1-3.5,3.5H3.5A3.5,3.5,0,0,1,0,47.94V42.11A4.67,4.67,0,0,1,4.66,37.44Z"
        fill="#60626e"
        fillRule="evenodd"
      />
      <circle cx="4.08" cy="47.36" r="1.75" fill="#c5d4a0" />
    </g>
    <motion.g
      initial={{
        x: -15,
        y: 15,
        rotate: -45,
      }}
      animate={{
        y: 0,
        x: 0,
        rotate: 0,
      }}
      transition={{ duration: 1, type: "spring" }}
    >
      <rect x="89.54" y="12.46" width="30" height="30" rx="7" fill="#fff" />
      <motion.path
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
        }}
        transition={{ delay: 0.2, duration: 1, type: "spring" }}
        d="M108,20.4a5,5,0,0,0-3,1l-.22.16-.21.18-.06.06-.07-.06a4.46,4.46,0,0,0-.42-.34,5,5,0,0,0-3-1A5.26,5.26,0,0,0,97.23,22a5.34,5.34,0,0,0-1.52,3.79A6.2,6.2,0,0,0,97.51,30l.28.31.19.2.3.3.22.2.35.33.41.35.46.39.73.6,2.53,2,.38.31a1.87,1.87,0,0,0,1.18.42,1.84,1.84,0,0,0,1.17-.42l2.92-2.34.56-.46.48-.4.42-.37.37-.33.33-.31.21-.2.19-.2.19-.2.18-.21a6.2,6.2,0,0,0,1.8-4.21A5.24,5.24,0,0,0,108,20.4Zm0,1.77a3.51,3.51,0,0,1,2.55,1,3.59,3.59,0,0,1,1,2.55,4.49,4.49,0,0,1-1.36,3l-.17.19-.27.28-.19.19-.31.3-.23.21-.39.34-.29.25-.5.41-.57.47L105,33.33l-.36.29,0,0a.08.08,0,0,1-.06,0l-1.81-1.46-.91-.73-.75-.61-.62-.52L100,30l-.33-.31-.3-.29-.19-.19L99,29l-.17-.19a4.48,4.48,0,0,1-1.35-3,3.59,3.59,0,0,1,1-2.55,3.48,3.48,0,0,1,2.54-1,3.33,3.33,0,0,1,2,.63,4.29,4.29,0,0,1,.83.82l.7.9.7-.9a4,4,0,0,1,.82-.82,3.34,3.34,0,0,1,2-.64Z"
        fill="#877fff"
      />
    </motion.g>
    <motion.rect
      initial={{
        x: 5,
        y: 5,
        opacity: 0,
      }}
      animate={{
        y: 0,
        x: 0,
        opacity: 1,
      }}
      transition={{ duration: 0.5, delay: 0.5 }}
      x="35.62"
      width="34.94"
      height="23.29"
      rx="1.75"
      fill="#cfcdec"
    />
    <motion.g
      initial={{
        x: 15,
        y: 30,
        rotate: 45,
      }}
      animate={{
        y: 0,
        x: 0,
        rotate: 0,
      }}
      transition={{ duration: 1, type: "spring" }}
    >
      <rect
        x="41.15"
        y="4.17"
        width="34.94"
        height="23.29"
        rx="1.75"
        fill="#9d80d4"
      />
      <path d="M41.15,8.24H76.09v6.41H41.15Z" fill="#fff" fillRule="evenodd" />
      <circle cx="65.9" cy="21.35" r="3.2" fill="#fff" />
      <circle
        cx="70.56"
        cy="21.35"
        r="3.2"
        fill="#fff"
        opacity="0.5"
        style={{ isolation: "isolate" }}
      />
    </motion.g>
  </svg>
)

const HotelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="132.2"
    height="175.07"
    viewBox="0 0 132.2 175.07"
  >
    <defs>
      <linearGradient
        id="navbar-hotel-icon"
        x1="-276.76"
        y1="607.79"
        x2="-277.66"
        y2="606.79"
        gradientTransform="matrix(71.15, 0, 0, -74.84, 19796.23, 45527.01)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fbdbaf" />
        <stop offset="1" stopColor="#ff9d41" />
      </linearGradient>
    </defs>
    <circle cx="42.22" cy="42.22" r="42.22" fill="#d5e4ff" />
    <g>
      <path
        d="M37.86,37.39H109v74.84H37.86Z"
        fillRule="evenodd"
        fill="url(#navbar-hotel-icon)"
      />
      <path
        d="M37.29,119.81h71.52v49.9H37.29Z"
        fill="#ffbf53"
        fillRule="evenodd"
      />
      <path
        d="M94.23,37.39H109V169.52H94.23Z"
        fill="#e5a161"
        fillRule="evenodd"
      />
      <path
        d="M32.32,27V24.36a.83.83,0,0,1,.83-.83h81.5a.83.83,0,0,1,.83.83V27a8.74,8.74,0,0,0-2.82,6.5v3.47a.84.84,0,0,1-.83.84H35.69a.84.84,0,0,1-.83-.84V33.47c0-1.8-.84-4-2.54-6.5Z"
        fill="#bd5f36"
        fillRule="evenodd"
      />
      <path
        d="M69.49,129.42h7.34a1.1,1.1,0,0,1,1.1,1.1,1.36,1.36,0,0,1,0,.2l-.52,2.88a1.1,1.1,0,0,1-1.08.9H70a1.09,1.09,0,0,1-1.08-.9l-.52-2.88a1.09,1.09,0,0,1,.88-1.28l.2,0Z"
        fill="#b6330a"
        fillRule="evenodd"
      />
      <path
        d="M32.32,112.36v-2.07a.83.83,0,0,1,.83-.83H112.8a.83.83,0,0,1,.83.83v2.07a7,7,0,0,0-2.75,5.48v2.8a.83.83,0,0,1-.83.83H35.64a.83.83,0,0,1-.83-.83v-2.8Q34.81,115.56,32.32,112.36ZM45.61,45h13.3l-.66,2.71a.78.78,0,0,1-.77.61H47a.78.78,0,0,1-.77-.61L45.61,45Z"
        fill="#ce7048"
        fillRule="evenodd"
      />
      <path
        d="M45.61,78.23h13.3L58.25,81a.79.79,0,0,1-.77.61H47a.79.79,0,0,1-.77-.61l-.66-2.72Z"
        fill="#bd5f36"
        fillRule="evenodd"
      />
      <path
        d="M66.4,45H79.7L79,47.68a.78.78,0,0,1-.77.61H67.83a.78.78,0,0,1-.77-.61L66.4,45Z"
        fill="#ce7048"
        fillRule="evenodd"
      />
      <path
        d="M66.4,78.23H79.7L79,81a.79.79,0,0,1-.77.61H67.83a.79.79,0,0,1-.77-.61l-.66-2.72Zm-7.1,53.5H87l-1.51,5a.8.8,0,0,1-.76.56H61.57a.8.8,0,0,1-.76-.56l-1.51-5Z"
        fill="#b6330a"
        fillRule="evenodd"
      />
      <path
        d="M87.19,45h13.3l-.66,2.71a.78.78,0,0,1-.77.61H88.62a.78.78,0,0,1-.77-.61L87.19,45Z"
        fill="#ce7048"
        fillRule="evenodd"
      />
      <path
        d="M87.19,78.23h13.3L99.83,81a.79.79,0,0,1-.77.61H88.62a.79.79,0,0,1-.77-.61l-.66-2.72Z"
        fill="#b6330a"
        fillRule="evenodd"
      />
      <path d="M49.77,43.3h5v3.33h-5Z" fill="#ce7048" fillRule="evenodd" />
      <path d="M49.77,76.57h5v3.32h-5Z" fill="#bd5f36" fillRule="evenodd" />
      <path d="M70.56,43.3h5v3.33h-5Z" fill="#ce7048" fillRule="evenodd" />
      <path d="M70.56,76.57h5v3.32h-5Z" fill="#b6330a" fillRule="evenodd" />
      <path
        d="M72.29,19.83h3.22a8.38,8.38,0,0,1,8.37,8.38A9,9,0,0,1,83.69,30H64.11a8.4,8.4,0,0,1,8.18-10.17Z"
        fill="#aa4b21"
        fillRule="evenodd"
      />
      <path d="M91.35,43.3h5v3.33h-5Z" fill="#ce7048" fillRule="evenodd" />
      <path d="M91.35,76.57h5v3.32h-5Z" fill="#b6330a" fillRule="evenodd" />
      <path d="M47.27,49.12h10v11h-10Z" fill="#82bbff" fillRule="evenodd" />
      <path
        d="M47.27,82.39h10V89h-10ZM68.06,49.12H78v9.15h-10Z"
        fill="#bddbff"
        fillRule="evenodd"
      />
      <path
        d="M68.06,82.39H78v10h-10ZM88.85,49.12h10v7.49h-10Z"
        fill="#82bbff"
        fillRule="evenodd"
      />
      <path d="M88.85,82.39h10v12h-10Z" fill="#bddbff" fillRule="evenodd" />
      <path
        d="M47.27,58.9l10-2.08v8.1h-10Zm0,28.48,10-1.67V98.19h-10ZM68.06,54.43l10-1.51v12h-10Zm0,37.11,10-1.67v8.32h-10Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M62.26,135.52H84.43v34.19H62.26Z"
        fill="#b6330a"
        fillRule="evenodd"
      />
      <path
        d="M40.63,127.94H48a.93.93,0,0,1,.92.93h0a.92.92,0,0,1-.92.92h-7.4a.92.92,0,0,1-.92-.92h0A.93.93,0,0,1,40.63,127.94Z"
        fill="#bd5f36"
        fillRule="evenodd"
      />
      <path
        d="M64.66,138.11h7.7v20.32h-7.7Zm9.24,0h8.31v20.32H73.9Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M73.9,138.11h8.31v6.65l-8.31,2.17Zm-9.24,0h7.7v13.38l-7.7,2Z"
        fill="#bddbff"
        fillRule="evenodd"
      />
      <circle cx="70.88" cy="161.83" r="1" fill="#ffbf53" />
      <circle cx="75.88" cy="161.83" r="1" fill="#ffbf53" />
      <path
        d="M88.85,56.61l10-2.5V64.92h-10Zm0,35.38,10-2.12v8.32h-10Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M45.61,64.92h13.3v1.14a.53.53,0,0,1-.53.53H46.14a.53.53,0,0,1-.53-.53V64.93Zm0,33.27h13.3v1.13a.53.53,0,0,1-.53.53H46.14a.53.53,0,0,1-.53-.53V98.19ZM66.4,64.92H79.7v1.14a.53.53,0,0,1-.53.53H66.93a.53.53,0,0,1-.53-.53V64.93Zm0,33.27H79.7v1.13a.53.53,0,0,1-.53.53H66.93a.53.53,0,0,1-.53-.53V98.19ZM87.19,64.92h13.3v1.14a.53.53,0,0,1-.53.53H87.72a.53.53,0,0,1-.53-.53V64.93Zm0,33.27h13.3v1.13a.53.53,0,0,1-.53.53H87.72a.53.53,0,0,1-.53-.53V98.19Z"
        fill="#bd5f36"
        fillRule="evenodd"
      />
      <path
        d="M32.1,169.52h83.6v5.55H32.1Z"
        fill="#b6330a"
        fillRule="evenodd"
      />
      <path
        d="M40.63,124.25H56.34a.93.93,0,0,1,.93.92h0a.93.93,0,0,1-.93.92H40.63a.92.92,0,0,1-.92-.92h0A.92.92,0,0,1,40.63,124.25Z"
        fill="#bd5f36"
        fillRule="evenodd"
      />
    </g>
    <g>
      <path
        d="M114.2,44.48l6.67-3.7a4.43,4.43,0,0,1,4.17-.05l7.16,3.75v57.66l-7.89,3.49a3.29,3.29,0,0,1-2.73,0l-7.38-3.46Z"
        fill="#435cff"
        fillRule="evenodd"
      />
      <path
        d="M122.5,56.65V53.3H124v3.35h1.35V49.24H124v3.1H122.5v-3.1h-1.35v7.41Z"
        fill="#fff"
      />
      <motion.path
        d="M123.36,66.82a2.32,2.32,0,0,0,1.27-.3,1.68,1.68,0,0,0,.66-.84,3.6,3.6,0,0,0,.21-1.29V61.61a3.47,3.47,0,0,0-.21-1.28,1.59,1.59,0,0,0-.66-.82,2.41,2.41,0,0,0-1.27-.29,2.36,2.36,0,0,0-1.25.29,1.56,1.56,0,0,0-.67.82,3.48,3.48,0,0,0-.2,1.28v2.77a3.63,3.63,0,0,0,.2,1.3,1.52,1.52,0,0,0,.67.84A2.27,2.27,0,0,0,123.36,66.82Zm0-1a.73.73,0,0,1-.5-.15.68.68,0,0,1-.21-.4,3.24,3.24,0,0,1-.05-.56V61.3a3.15,3.15,0,0,1,.05-.56.63.63,0,0,1,.71-.53.78.78,0,0,1,.5.14.74.74,0,0,1,.21.39,2.4,2.4,0,0,1,.06.56v3.41a2.47,2.47,0,0,1-.06.56.63.63,0,0,1-.71.55Z"
        fill="#fff"
        animate={{
          opacity: [0.2, 0.6, 1, 0.2, 1],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
        }}
      />
      <path d="M124.88,96.9V96h-1.79V89.49h-1.35V96.9Z" fill="#fff" />
      <polygon
        points="123.75 76.78 123.75 70.36 124.91 70.36 124.91 69.37 121.22 69.37 121.22 70.36 122.4 70.36 122.4 76.78 123.75 76.78"
        fill="#fff"
      />
      <polygon
        points="124.8 86.84 124.8 85.91 123.03 85.91 123.03 83.46 124.41 83.46 124.41 82.51 123.03 82.51 123.03 80.38 124.78 80.38 124.78 79.43 121.67 79.43 121.67 86.84 124.8 86.84"
        fill="#fff"
      />
    </g>
  </svg>
)

// const GameIcon = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     xmlnsXlink="http://www.w3.org/1999/xlink"
//     width="203.46"
//     height="206.58"
//     viewBox="0 0 203.46 206.58"
//   >
//     <defs>
//       <radialGradient
//         id="navbar-game-icon-a"
//         cx="-258.2"
//         cy="603.54"
//         r="1.46"
//         gradientTransform="matrix(58.71, 0, 0, -59.92, 15256.01, 36296.4)"
//         gradientUnits="userSpaceOnUse"
//       >
//         <stop offset="0" stopColor="#96eeff" />
//         <stop offset="1" stopColor="#0a8de8" />
//       </radialGradient>
//       <mask
//         id="navbar-game-icon-b"
//         x="18.49"
//         y="89.44"
//         width="160.28"
//         height="92.47"
//         maskUnits="userSpaceOnUse"
//       >
//         <path
//           d="M152.1,167.22c-29.73,17.09-78.06,17.09-108,0S14,122.43,43.77,105.34s78-17.09,108,0,30,44.8.36,61.88Z"
//           fill="#fff"
//           fillRule="evenodd"
//         />
//       </mask>
//     </defs>
//     <circle cx="147.71" cy="52.59" r="31.94" fill="#f1d9ff" />
//     <g>
//       <path
//         d="M168.62,188.51c-38.41,24.09-100.85,24.09-139.5,0s-38.85-63.11-.45-87.19,100.84-24.08,139.49,0,38.83,63.13.46,87.18Z"
//         fill="#b155d6"
//         fillRule="evenodd"
//       />
//       <path
//         d="M161.61,173.18c-35.41,19.87-93,19.87-128.6,0s-35.82-52.06-.42-71.92,93-19.87,128.6,0,35.79,52.07.42,71.92Z"
//         fill="#fac77c"
//         fillRule="evenodd"
//       />
//       <path
//         d="M152.1,167.22c-29.73,17.09-78.06,17.09-108,0S14,122.43,43.77,105.34s78-17.09,108,0S181.79,150.14,152.1,167.22Z"
//         fillRule="evenodd"
//         fill="url(#navbar-game-icon-a)"
//       />
//       <g mask="url(#navbar-game-icon-b)">
//         <path
//           d="M100.47,136l.19-.34v.37l75,13.75L169,163.42l-68.33-26.94,0,.88L148,176l-22.15,5.95-25.06-42.66,1,42.66-24.66-2.84,22.63-41.61-1-1.79h0Zm-1.83-.34-3.33,3.14,3.33-.06-4.15.84L55.87,176.09l-17.3-9.36,50.28-26-66.6,13.43-3.76-14,74-1.34Zm0,0,1.39,1.14.27-.48ZM95.55,89.44l27.75,2.78L98.55,135.54l.09.14-.12-.09,0,0,.14.05-.16,0v0l-76.88-12.6L32.5,111l66,24.6v-.07L49.32,99.09l21.42-6.56,27.71,42.86ZM175,120.27l3.77,14.43-80.14,1L175,120.27Zm-28.6-21.58,17,9.57L98.64,135.68l47.77-37Z"
//           fill="#7d3dae"
//           fillRule="evenodd"
//         />
//       </g>
//       <g>
//         <path
//           d="M79,139.33c0,2.72,1.77,5.43,5.34,7.49l.46.26h0l.14.08h0l.12.06,0,0,.12.05,0,0,.1,0,.08,0,.08,0,.11,0,.06,0,.13.06,0,0,.15.07h0l.16.07h0l.06,0,.62.25.53.2h0l.12,0,.09,0,.12,0,.1,0,.08,0a.13.13,0,0,0,.08,0c.35.12.71.23,1.07.33.61.18,1.24.33,1.88.46s1.2.23,1.81.32,1,.13,1.55.18l.32,0c.56,0,1.13.07,1.7.08s1.26,0,1.88,0,1.37-.07,2-.13l.71-.08h.15l.19,0h0l.17,0h.06l.13,0,.94-.15a26.49,26.49,0,0,0,3.51-.88,21,21,0,0,0,3.12-1.27l0,0,.1-.05.08,0,.12-.06.08-.05.07,0,.14-.08,0,0,.11-.07,0,0,.15-.09a14.68,14.68,0,0,0,1.24-.79l.06,0,.07-.05a13.87,13.87,0,0,0,1.22-1,9.42,9.42,0,0,0,1-1.08l.07-.08,0,0,.08-.1h0l.08-.1,0,0,0,0,.06-.09a0,0,0,0,0,0,0c.18-.25.34-.51.49-.76l0-.06,0-.09v0l0-.09a8.08,8.08,0,0,0,.35-.8,6.47,6.47,0,0,0,.3-1,6.26,6.26,0,0,0,.12-1.05h0v-3.88a.2.2,0,0,1,0,.07h0a5.13,5.13,0,0,1-.11,1,6.46,6.46,0,0,1-.26.93.43.43,0,0,0,0,0s0,0,0,0-.09.24-.14.37a.09.09,0,0,0,0,.07l0,0,0,.1h0a.54.54,0,0,0,0,.11v0l0,.08c-.1.2-.21.4-.33.59h0l0,.06a7.67,7.67,0,0,1-.54.77,12,12,0,0,1-1.15,1.24,14.56,14.56,0,0,1-2.37,1.75l-.23.13-.15.09,0,0a21.47,21.47,0,0,1-3.9,1.7c-.86.27-1.75.51-2.66.7l-.08,0-.12,0c-.51.11-1,.2-1.56.28s-1,.13-1.46.18c-.68.07-1.36.11-2,.14H96.42q-.9,0-1.8-.09l-.23,0c-.82-.08-1.63-.18-2.42-.32l-.93-.17-.65-.15h0l-.09,0-.15,0h0l-.19,0h0c-.74-.19-1.46-.41-2.17-.66l0,0-.08,0-.12,0,0,0-.42-.16a19.63,19.63,0,0,1-2.61-1.25c-3.57-2.06-5.35-4.77-5.34-7.49v3.9Z"
//           fill="#7d00b1"
//           fillRule="evenodd"
//         />
//         <path
//           d="M84.42,127.88c-7.19,4.18-7.21,10.92-.07,15s18.78,4.09,26-.09,7.21-10.91.07-15a26.38,26.38,0,0,0-12.86-3.07,26.71,26.71,0,0,0-13.11,3.16"
//           fill="#b155d6"
//           fillRule="evenodd"
//         />
//         <path
//           d="M115.91,142.68a3.57,3.57,0,0,0-.73.55c-2.26-1-5-2.44-7.9-4.09a69.88,69.88,0,0,1-7.94-5.21,4.67,4.67,0,0,0,.78-.37,3.22,3.22,0,0,0,1.13-1,83.75,83.75,0,0,1,9.27,4.7,74.94,74.94,0,0,1,7.34,4.73h0a7.56,7.56,0,0,1,5,.68c1.93,1.11,1.91,2.89,0,4a7.52,7.52,0,0,1-6.88,0c-1.63-.93-1.87-2.35-.76-3.43a3.57,3.57,0,0,1,.73-.55l.35-.19ZM92.13,132.57a3.3,3.3,0,0,0,1.11,1,5.48,5.48,0,0,0,1.43.57c-1.69,1.22-3.9,2.64-6.43,4.11s-4.92,2.73-7,3.7c.71,1,.36,2.21-1.06,3a7.57,7.57,0,0,1-6.9,0c-1.9-1.09-1.94-2.87,0-4a7.66,7.66,0,0,1,5.4-.59,6.15,6.15,0,0,1,.76.25,6.15,6.15,0,0,0-.76-.25c1.67-1.19,3.85-2.59,6.33-4S90,133.56,92.13,132.57ZM122,117.07c1.89,1.1,1.9,2.9,0,4a7.69,7.69,0,0,1-5.7.52l-.32-.1.32.1a69.75,69.75,0,0,1-7.54,5c-2.73,1.58-5.37,2.95-7.56,4a3.21,3.21,0,0,0-1.07-.93,5.86,5.86,0,0,0-1.45-.58,78.93,78.93,0,0,1,6.78-4.36,86.21,86.21,0,0,1,8.59-4.42l.16.19-.16-.19c-.78-1-.45-2.29,1-3.14A7.68,7.68,0,0,1,122,117.07Zm-43.17-.21c1.51.88,1.82,2.17,1,3.21a3.61,3.61,0,0,1-1,.78l-.32.17.32-.17a3.81,3.81,0,0,0,1-.78c2.16,1,4.73,2.33,7.38,3.87a69.66,69.66,0,0,1,7.69,5,6,6,0,0,0-1.63.63,3.62,3.62,0,0,0-.82.63,87.59,87.59,0,0,1-8.52-4.37,78.8,78.8,0,0,1-6.79-4.34h0a7.57,7.57,0,0,1-5.17-.63c-1.92-1.12-1.91-2.9,0-4A7.58,7.58,0,0,1,78.81,116.86Z"
//           fill="#fff"
//           fillRule="evenodd"
//         />
//         <path
//           d="M94.85,128.94a6.37,6.37,0,0,0-1.63.64,3.62,3.62,0,0,0-.82.63,1.79,1.79,0,0,0-.27,2.36,3.2,3.2,0,0,0,1.11,1,5.48,5.48,0,0,0,1.43.57,8,8,0,0,0,4.67-.2,4.67,4.67,0,0,0,.78-.37,3.32,3.32,0,0,0,1.13-1,1.74,1.74,0,0,0,0-2.06,3.11,3.11,0,0,0-1.07-.93,5.86,5.86,0,0,0-1.45-.58,8.15,8.15,0,0,0-2-.24,8.64,8.64,0,0,0-1.84.2"
//           fill="#7d00b1"
//           fillRule="evenodd"
//         />
//       </g>
//     </g>
//     <motion.g
//       animate={{
//         y: [30, 0, 30, 0],
//         rotate: [-45, 0, -45, 0],
//       }}
//       transition={{
//         duration: 2,
//         ease: "easeInOut",
//       }}
//     >
//       <path
//         d="M167.14,56.29a112.57,112.57,0,0,0-5.72,11.29q3.48,2.57,7.07,4.92,6,3.9,12.19,7.18c.16-.41.33-.82.5-1.23.35-.82.7-1.64,1.06-2.44.73-1.61,1.5-3.2,2.31-4.75.6-1.15,1.23-2.29,1.87-3.4a0,0,0,0,1,0,0c.64-1.12,1.3-2.23,2-3.31,1.37-2.17,2.83-4.26,4.35-6.26.75-1,1.51-2,2.29-2.89l0-.05a85.91,85.91,0,0,1,8.35-8.77,124.3,124.3,0,0,1-12.5-19,110.13,110.13,0,0,0-23.82,28.75Z"
//         fill="#fff"
//         fillRule="evenodd"
//       />
//       <path
//         d="M190.91,31.82l-.07,0a.08.08,0,0,0,0-.07c0-.32-.12-.77-.39-.92a.48.48,0,0,0-.39,0,1.72,1.72,0,0,0-.63.5,2.6,2.6,0,0,0-.54,1.35,3.12,3.12,0,0,0,0,.61s0,.26,0,.34v0h0l.29.12a2.88,2.88,0,0,0,.49.11,1.8,1.8,0,0,0,1.22-.28,1.7,1.7,0,0,0,.55-.58.76.76,0,0,0,.09-.44c0-.36-.38-.57-.62-.72M180.72,72.63a3.78,3.78,0,0,0-.21-.56s-.13-.24-.16-.32H180a3.16,3.16,0,0,0-.5.1,1.83,1.83,0,0,0-1,.73,1.92,1.92,0,0,0-.27.76.83.83,0,0,0,.09.44,1,1,0,0,0,.86.41.09.09,0,0,0,.07,0s0,0,0,.06c.17.28.41.67.72.69a.46.46,0,0,0,.35-.14,1.47,1.47,0,0,0,.39-.7,2.68,2.68,0,0,0,0-1.46m8.37-20.41c-.38-1.25-1.73-1.79-2.71-2.19a.61.61,0,0,0-.24-.07.49.49,0,0,0,0-.24c-.39-1.12-.92-2.7-2-3.06a1.7,1.7,0,0,0-1.39.22A5.63,5.63,0,0,0,180.77,49a9.72,9.72,0,0,0-1.1,5.22,14.25,14.25,0,0,0,.26,2.19c0,.12.27.93.32,1.25l0,0v0s0,0,0,0h0v0c.24,0,1,.24,1.11.25a9.65,9.65,0,0,0,1.86.07,6.49,6.49,0,0,0,4.17-1.78A6,6,0,0,0,189,53.88a2.77,2.77,0,0,0,.05-1.66"
//         fill="#ff1b10"
//         fillRule="evenodd"
//       />
//     </motion.g>
//     <motion.g
//       animate={{
//         y: [15, 0, 15, 0],
//         rotate: [270, 0, 270, 0],
//       }}
//       transition={{
//         duration: 2,
//         ease: "easeInOut",
//       }}
//     >
//       <path
//         d="M53.41,65.33l-9.3,9.8,14.21-2.5,9.39-9.7Z"
//         fill="#c97903"
//         fillRule="evenodd"
//       />
//       <path
//         d="M45.2,49.27h0l-8.5,9.25-.62.69h0l8,15.91,9.31-9.81L45.21,49.26Z"
//         fill="#c97903"
//         fillRule="evenodd"
//       />
//       <path d="M59.51,47.1l-14.3,2.16,8.2,16.07,14.3-2.4Z" fill="none" />
//       <path
//         d="M59.51,47.1l-14.3,2.16,8.2,16.07,14.3-2.4Z"
//         fill="#fac77c"
//         fillRule="evenodd"
//       />
//       <path
//         d="M56.63,57.67a1.58,1.58,0,1,1-.27-3.14A1.56,1.56,0,0,1,58.06,56a1.58,1.58,0,0,1-1.43,1.71M58,51.85a1.58,1.58,0,1,1,1.44-1.71A1.58,1.58,0,0,1,58,51.85M55.26,63.49a1.58,1.58,0,1,1,1.43-1.7,1.57,1.57,0,0,1-1.43,1.7m-9.8.1A1.22,1.22,0,0,1,43.62,63a1.82,1.82,0,0,1,.4-2.24,1.23,1.23,0,0,1,1.85.56,1.81,1.81,0,0,1-.41,2.24m11.06,7.68a2.72,2.72,0,0,1,2.18-.45c.47.23.25.78-.49,1.24A2.75,2.75,0,0,1,56,72.5c-.47-.22-.25-.77.49-1.23m-3-4.85A2.78,2.78,0,0,1,55.75,66c.47.22.25.77-.49,1.23a2.78,2.78,0,0,1-2.19.44c-.47-.22-.24-.77.49-1.23"
//         fill="#fdfffe"
//         fillRule="evenodd"
//       />
//     </motion.g>
//     <motion.g
//       animate={{
//         y: [20, 0, 20, 0],
//       }}
//       transition={{
//         duration: 2,
//         ease: "easeInOut",
//       }}
//     >
//       <path
//         d="M143.14,34.9A120.1,120.1,0,0,1,119.5,51.76,119.4,119.4,0,0,1,87,8.46,105.71,105.71,0,0,0,113.11,0a99.1,99.1,0,0,0,30,34.9"
//         fill="#fff"
//         fillRule="evenodd"
//       />
//       <path
//         d="M94.24,12.4c-.08.25-.44.65-.84.51a1.59,1.59,0,0,0-.2-.07c.21.26.43.51.65.77l-1,.25c-.08-.31-.15-.61-.22-.91h0a.18.18,0,0,0,0,.1c-.12.36-.83.23-1.15.06A1,1,0,0,1,90.87,12a3.35,3.35,0,0,1,.29-1,5.21,5.21,0,0,1,.24-.47l.12-.22c.06-.1,0-.11.18-.07a6.44,6.44,0,0,1,1.13.52,4.88,4.88,0,0,1,.73.44c.1.06.19.14.29.21a.89.89,0,0,1,.39,1m26,12.58c-.07.93-.94,2.65-2.4,2.5a4.35,4.35,0,0,0-.79-.09c1,.72,2,1.43,3,2.13q-1.53.87-3.09,1.71c-.56-1-1.1-2-1.62-3a.63.63,0,0,0-.12.1.63.63,0,0,0-.06.36c-.07,1.36-2.68,1.46-3.95,1.07a3.85,3.85,0,0,1-2.89-3.61,11.38,11.38,0,0,1,.34-3.64c.14-.62.32-1.22.51-1.81l.3-.86c.14-.39.09-.43.56-.37a29.41,29.41,0,0,1,4.19,1,24,24,0,0,1,2.78.95c.38.16.75.33,1.12.52,1.34.69,2.25,1.66,2.16,3.05m17,8.05a3.9,3.9,0,0,1,.18,1v.79c0,.11,0,.12-.12.14A6.74,6.74,0,0,1,136,35a5,5,0,0,1-.85-.12,3.15,3.15,0,0,1-.36-.09c-.43-.14-.75-.38-.78-.77s.15-.78.56-.8a.81.81,0,0,0,.22,0l-.9-.47.78-.6c.18.26.37.51.56.76l0,0a.41.41,0,0,0,0-.11c-.05-.38.64-.54,1-.51a1.07,1.07,0,0,1,1,.8"
//         fill="#302f2f"
//         fillRule="evenodd"
//       />
//     </motion.g>
//   </svg>
// )

const DropdownWithPreview = ({ items, pathname, category }) => {
  const pageName = pathname.split("/")[2]
  const currentTab = items.find(item => item.link === `/${pageName}`)
  const [hoveredLink, setHoveredLink] = useState(
    currentTab ? currentTab : items[0]
  )
  const { t } = useTranslation()
  return (
    <DropdownCard width={600} className="is-flex">
      <div
        style={{ backgroundColor: hoveredLink.bgColor }}
        className="preview-section"
      >
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={hoveredLink.title}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="icon main-icon"
            style={
              hoveredLink.topValueIcon ? { top: hoveredLink.topValueIcon } : {}
            }
          >
            {hoveredLink.icon}
          </motion.div>
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          <TwistedShape
            key={hoveredLink.title}
            color1={hoveredLink.shapeColors[0]}
            color2={hoveredLink.shapeColors[1]}
            top={hoveredLink.topValue}
          />
        </AnimatePresence>
        {category === "product" && (
          <Link
            to="/secure-online-payment-services-pci-dss"
            className="is-flex pci-link"
          >
            <span className="icon has-margin-right-4">
              <LockIcon />
            </span>
            <p>{t("navbar_item1_submenu_footer")}</p>
          </Link>
        )}
      </div>
      <div className="links-section">
        {items.map(item => (
          <Link
            onMouseEnter={() => setHoveredLink(item)}
            to={item.link}
            key={item.title}
            className="item-wrapper"
          >
            <h3
              style={{ color: hoveredLink.title === item.title && "#4d54ff" }}
              className="title is-6"
            >
              {item.title}
            </h3>
            <p className="subtitle is-7">{item.subtitle}</p>
          </Link>
        ))}
      </div>
    </DropdownCard>
  )
}

const CompanyDropdown = ({ blogPosts, pathname }) => {
  const { t } = useTranslation()
  const items = [
    {
      title: t("navbar_item4_submenu_item1_title"),
      icon: <TIcon />,
      link: "/about",
    },
    {
      title: t("navbar_item4_submenu_item2_title"),
      icon: <DownloadIcon />,
      link: "/logos-resources",
    },
    {
      title: t("navbar_item4_submenu_item3_title"),
      icon: <FAQIcon />,
      link: "/faq",
    },
  ]
  const pageName = pathname.split("/")[2]

  return (
    <DropdownCard width={400}>
      <div className="links-wrapper">
        {items.map((item, i) => (
          <Link
            to={item.link}
            key={item.title}
            className="item-wrapper is-flex is-vcentered"
          >
            <span className="icon">{item.icon}</span>
            <h3
              style={{ color: `/${pageName}` === item.link && "#4d54ff" }}
              className="title is-6 has-margin-bottom-0"
            >
              {item.title}
            </h3>
            <p className="subtitle is-7">{item.subtitle}</p>
          </Link>
        ))}
      </div>
      <div className="blog-wrapper">
        <Link
          to="/blog"
          className="has-margin-bottom-4 is-block is-size-6 blog-link"
        >
          {t("navbar_item4_submenu_blog")}
        </Link>
        {blogPosts.map(({ node: post }) => (
          <Link
            key={post.id}
            to={`/blog/${post.slug}`}
            className="is-flex post-wrapper"
          >
            <span className="icon">
              <Img fixed={post.author.image.fixed} />
            </span>
            {post.title}
          </Link>
        ))}
      </div>
    </DropdownCard>
  )
}
const ResponsiveNavbar = ({ hasLightBackground }) => {
  const { t } = useTranslation()
  const [isOpened, setIsOpened] = useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const { locale } = useIntl()
  const menuEL = useRef(null)
  const variantsBG = {
    open: {
      clipPath: "circle(150% at 100% 0)",
      transition: {
        type: "spring",
        stiffness: 200,
        restDelta: 2,
        damping: 60,
      },
    },
    closed: {
      clipPath: "circle(0.0% at 100% 0)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  }
  const variantsLinks = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }
  const variantsLink = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }
  const variantsSubMenu = {
    open: {
      height: "initial",
      transition: { staggerChildren: 0.07, delayChildren: 0.5 },
    },
    closed: {
      height: 0,
      transition: { delay: 0.5, staggerChildren: 0.05, staggerDirection: -1 },
    },
  }
  const variantsLanguageSwitcher = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  }
  useEffect(() => {
    if (isOpened) {
      window.scrollTo(0, 0)
      disableBodyScroll(menuEL.current)
    } else clearAllBodyScrollLocks()
  }, [isOpened])
  useLayoutEffect(() => {
    function updateSize() {
      setMenuHeight(window.innerHeight)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  const links = [
    {
      title: t("navbar_item1_text"),
      link: "/product",
      children: [
        {
          title: t("navbar_item1_submenu_item1_title"),
          link: "/product",
          icon: <GatewayIcon />,
        },
        {
          title: t("navbar_item1_submenu_item3_title"),
          link: "/payment-links",
          icon: <PaymentLinksResponsiveIcon />,
        },
        {
          title: t("navbar_item1_submenu_item4_title"),
          link: "/integrations",
          icon: <PluginIcon />,
        },
      ],
    },
    {
      title: t("navbar_item2_text"),
      link: "/online-payment-services-travel-agencies",
      children: [
        {
          title: t("navbar_item2_submenu_item1_title"),
          link: "#eCommerce",
        },
        {
          title: t("navbar_item2_submenu_item2_title"),
          link: "/online-payment-services-travel-agencies",
        },
        // {
        //   title: t("navbar_item2_submenu_item3_title"),
        //   link: "/payment-services-betting-gambling",
        // },
      ],
    },
    // {
    //   title: t("navbar_item3_text"),
    //   link: "/pricing",
    // },
    {
      title: t("navbar_item4_text"),
      link: "/about",
      children: [
        {
          title: t("navbar_item4_submenu_item1_title"),
          link: "/about",
        },
        {
          title: t("navbar_item4_submenu_item2_title"),
          link: "/logos-resources",
        },
        {
          title: t("navbar_item4_submenu_item3_title"),
          link: "/faq",
        },
      ],
    },
    // {
    //   title: "DEVELOPERS",
    //   link: "/product",
    // },
  ]
  const [selectedSubMenu, setSelectedSubMenu] = useState(null)
  const [isLanguagesMenuOpen, setIsLanguagesMenuOpen] = useState(false)
  return (
    <div
      className={`navbar-wrapper-responsive ${
        hasLightBackground ? "has-light-background" : ""
      } ${isOpened ? "is-opened" : ""} is-hidden-desktop`}
    >
      <div className="is-flex is-hspaced is-vcentered main-menu">
        <Link to="/" className="logo-wrapper is-flex is-vcentered">
          <TwispayLogo />
        </Link>
        <span
          onClick={() => {
            setIsOpened(!isOpened)
            setSelectedSubMenu(null)
          }}
          onKeyPress={() => {
            setIsOpened(!isOpened)
            setSelectedSubMenu(null)
          }}
          role="button"
          tabIndex="0"
          className={`menu-btn `}
        >
          <span className="line" />
          <span className="line" />
        </span>
      </div>
      <motion.div
        ref={menuEL}
        initial="closed"
        animate={isOpened ? "open" : "closed"}
        variants={variantsBG}
        style={{ height: menuHeight }}
        className="responsive-menu "
      >
        <AnimatePresence>
          {isLanguagesMenuOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="overlay"
              onClick={() => setIsLanguagesMenuOpen(false)}
              onKeyPress={e => setIsLanguagesMenuOpen(false)}
              role="button"
              tabIndex="0"
            />
          )}
          {isOpened && (
            <TwistedShape
              key="twisted-shape-responsive"
              color1="#388bd8"
              color2="#69bfee"
              top={80}
            />
          )}
        </AnimatePresence>
        <motion.ul className="categories-wrapper" variants={variantsLinks}>
          {links.map(link => (
            <motion.li
              className="category"
              variants={variantsLink}
              key={link.title}
              onClick={() =>
                setSelectedSubMenu(
                  selectedSubMenu === link.title ? null : link.title
                )
              }
              onKeyPress={() =>
                setSelectedSubMenu(
                  selectedSubMenu === link.title ? null : link.title
                )
              }
              role="button"
              tabIndex="0"
            >
              <div
                className={`is-flex is-hspaced ${
                  selectedSubMenu === link.title ? "has-margin-bottom-4" : ""
                }`}
              >
                {link.children ? (
                  <>
                    <p className="main-link">{link.title}</p>
                    <span
                      className={`icon arrow-icon ${
                        selectedSubMenu === link.title ? "is-opened" : ""
                      }`}
                    >
                      <ArrowDown />
                    </span>
                  </>
                ) : (
                  <Link to={link.link} className="main-link">
                    {link.title}
                  </Link>
                )}
              </div>
              <AnimatePresence>
                {link.children && selectedSubMenu === link.title && (
                  <motion.ul
                    key={selectedSubMenu}
                    variants={variantsSubMenu}
                    initial="closed"
                    animate="open"
                    exit="closed"
                    className="submenu-wrapper has-margin-left-4"
                  >
                    {link.children.map(subLink => (
                      <motion.li key={subLink.title} variants={variantsLink}>
                        <Link
                          key={subLink.title}
                          to={subLink.link}
                          className="is-flex is-vcentered"
                          onClick={() => {
                            clearAllBodyScrollLocks()
                            setIsOpened(false)
                          }}
                        >
                          {subLink.icon && (
                            <span className="icon">{subLink.icon}</span>
                          )}
                          {subLink.title}
                        </Link>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </motion.li>
          ))}
        </motion.ul>
        <div className="navbar-footer is-flex is-vcentered is-hspaced">
          <Link
            to="/contact"
            className="contact-btn has-text-white has-margin-right-5"
          >
            {t("Contact us")}
            <span
              style={{ widht: 19, height: 19, padding: 5 }}
              className="icon has-margin-left-1"
            >
              <ArrowRightIcon />
            </span>
          </Link>
          <div className="language-switcher ">
            <div
              onClick={() => setIsLanguagesMenuOpen(true)}
              onKeyPress={e => setIsLanguagesMenuOpen(true)}
              role="button"
              tabIndex="0"
              style={{ cursor: "pointer" }}
              className="is-flex is-vcentered"
            >
              <span className="icon has-margin-right-3">
                <EarthIcon />
              </span>
              <span className="selected-language has-text-white">
                {locale.toUpperCase()}
              </span>
            </div>
            <AnimatePresence>
              {isLanguagesMenuOpen && (
                <motion.div
                  key="language-switcher"
                  variants={variantsLanguageSwitcher}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <LanguageSwitcher isMobile />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

const LanguageSwitcher = ({ isMobile }) => {
  const languages = [
    {
      title: "English",
      locale: "en",
    },
    {
      title: "Română",
      locale: "ro",
    },
    {
      title: "Deutsch",
      locale: "de",
    },
    {
      title: "Italiano",
      locale: "it",
    },
  ]
  const handleKeyPress = (event, language) => {
    if (event.key === "Enter") {
      changeLocale(language)
    }
  }
  const { locale } = useIntl()
  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (!window.localStorage.getItem("gatsby-intl-language")) {
        fetch(
          `https://pro.ip-api.com/json?key=${process.env.IPAPI_KEY}&fields=countryCode`
        )
          .then(response => response.json())
          .then(resultData => {
            const countryCode = resultData.countryCode.toLowerCase()
            if (
              countryCode === "ro" ||
              countryCode === "de" ||
              countryCode === "it"
            )
              changeLocale(countryCode)
            else if (countryCode === "at" || countryCode === "ch")
              changeLocale("de")
            else changeLocale("en")
          })
      }
    }
  }, [])
  const Languages = (
    <ul className="languages">
      {languages.map((language, i) => (
        <li
          className={` ${locale === language.locale ? "selected" : ""}`}
          key={language.title}
        >
          <div
            onClick={() => changeLocale(language.locale)}
            onKeyPress={e => handleKeyPress(e, language.locale)}
            role="button"
            tabIndex="0"
          >
            {language.title}
          </div>
        </li>
      ))}
    </ul>
  )
  if (isMobile) {
    return Languages
  }
  return <DropdownCard>{Languages}</DropdownCard>
}
const Navbar = ({ hasLightBackground, ...props }) => {
  const { t } = useTranslation()
  const { locale } = useIntl()
  const [openedDropdown, setOpenedDropdown] = useState(null)
  const [isNavbarHover, setIsNavbarHover] = useState(false)
  // asdasdasdadasdada
  const queries = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(
        sort: { fields: [createdAt], order: DESC }
        limit: 2
      ) {
        edges {
          node {
            id
            title
            createdAt
            slug
            author {
              image {
                fixed(width: 32, height: 32) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  const blogPosts = [...queries.allContentfulBlogPost.edges]
  const { pathname } = window.location
  const productItems = [
    {
      title: t("navbar_item1_submenu_item1_title"),
      subtitle: t("navbar_item1_submenu_item1_subtitle"),
      bgColor: "#e1e8ff",
      icon: <PaymentGatewayIcon />,
      link: "/product",
      shapeColors: ["#388bd8", "#69bfee"],
      topValue: 30,
    },
    // {
    //   title: "Transaction Dashboard",
    //   subtitle: "A complete overview of all finance indicators",
    //   bgColor: "#eae2ff",
    //   icon: <TransactionDashboardIcon />,
    //   link: "/product",
    //   shapeColors: ["#a17afe", "#a17afe"],
    // },
    {
      title: t("navbar_item1_submenu_item3_title"),
      subtitle: t("navbar_item1_submenu_item3_subtitle"),
      bgColor: "#d7f0ff",
      icon: <PaymentLinksIcon />,
      link: "/payment-links",
      shapeColors: ["#4896ee", "#69bfee"],
      topValue: 30,
    },
    {
      title: t("navbar_item1_submenu_item4_title"),
      subtitle: t("navbar_item1_submenu_item4_subtitle"),
      bgColor: "#ebffe9",
      icon: <EcommercePluginIcon />,
      link: "/integrations",
      shapeColors: ["#05d004", "#3db200"],
      topValue: 30,
    },
  ]

  const industryItems = [
    // {
    //   title: "Airlines",
    //   subtitle: "Custom features and years of experience",
    //   bgColor: "#e1e8ff",
    //   icon: <PaymentGatewayIcon />,
    //   link: "/product",
    //   shapeColors: ["#388bd8", "#69bfee"],
    // },
    {
      title: t("navbar_item2_submenu_item1_title"),
      subtitle: t("navbar_item2_submenu_item1_subtitle"),
      bgColor: "#eff7e9",
      icon: <CartIcon />,
      link: "#eCommerce",
      shapeColors: ["#9abe7f", "#9dbca2"],
      topValueIcon: 56,
    },
    {
      title: t("navbar_item2_submenu_item2_title"),
      subtitle: t("navbar_item2_submenu_item2_subtitle"),
      bgColor: "#e7f1ff",
      icon: <HotelIcon />,
      link: "/online-payment-services-travel-agencies",
      shapeColors: ["#97b9ff", "#8696ff"],
      topValueIcon: 33,
    },
    // {
    //   title: t("navbar_item2_submenu_item3_title"),
    //   subtitle: t("navbar_item2_submenu_item3_subtitle"),
    //   bgColor: "#f6e7ff",
    //   icon: <GameIcon />,
    //   link: "/payment-services-betting-gambling",
    //   shapeColors: ["#e597ff", "#ffb2f7"],
    //   topValueIcon: 40,
    // },
  ]

  return (
    <nav role="navigation" aria-label="main navigation">
      <AnimatePresence>
        {isNavbarHover && (
          <motion.div
            key="overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="overlay"
          ></motion.div>
        )}
      </AnimatePresence>
      <div className="container">
        <div
          onMouseEnter={() => setIsNavbarHover(true)}
          onMouseLeave={() => setIsNavbarHover(false)}
          role="presentation"
          className={`navbar-wrapper is-hidden-touch ${
            hasLightBackground && !isNavbarHover ? "has-light-background" : ""
          }`}
        >
          <Link className="logo-wrapper is-flex is-vcentered">
            <TwispayLogo />
          </Link>
          <ul className="menu-wrapper is-flex is-centered-centered">
            <li className=" has-margin-right-5">
              <div
                onMouseEnter={() => setOpenedDropdown("product")}
                onMouseLeave={() => setOpenedDropdown(null)}
                onKeyPress={() => setOpenedDropdown("product")}
                role="button"
                tabIndex="0"
              >
                <Link className=" menu-item" to="/product">
                  {t("navbar_item1_text")}
                </Link>
                <AnimatePresence>
                  {openedDropdown === "product" && (
                    <DropdownWithPreview
                      key="product"
                      category="product"
                      items={productItems}
                      pathname={pathname}
                    />
                  )}
                </AnimatePresence>
              </div>
            </li>
            <li className=" has-margin-right-5">
              <div
                onMouseEnter={() => setOpenedDropdown("industry")}
                onMouseLeave={() => setOpenedDropdown(null)}
                onKeyPress={() => setOpenedDropdown("industry")}
                role="button"
                tabIndex="0"
              >
                <Link
                  className=" menu-item"
                  to="/online-payment-services-travel-agencies"
                >
                  {t("navbar_item2_text")}
                </Link>

                <AnimatePresence>
                  {openedDropdown === "industry" && (
                    <DropdownWithPreview
                      key="industry"
                      category="industry"
                      pathname={pathname}
                      items={industryItems}
                    />
                  )}
                </AnimatePresence>
              </div>
            </li>
            {/* <li className=" has-margin-right-5">
              <Link className=" menu-item" to="/pricing">
                {t("navbar_item3_text")}
              </Link>
            </li> */}
            <li className=" has-margin-right-5">
              <div
                onMouseEnter={() => setOpenedDropdown("company")}
                onMouseLeave={() => setOpenedDropdown(null)}
                onKeyPress={() => setOpenedDropdown("company")}
                role="button"
                tabIndex="0"
              >
                <Link className=" menu-item" to="/about">
                  {t("Company")}
                </Link>
                <AnimatePresence>
                  {openedDropdown === "company" && (
                    <CompanyDropdown
                      key="company"
                      blogPosts={blogPosts}
                      pathname={pathname}
                    />
                  )}
                </AnimatePresence>
              </div>
            </li>
            {/* <li>
              <Link className=" menu-item" to="/integrations">
                {t("Developers")}
              </Link>
            </li> */}
          </ul>
          <div className="btns-wrapper is-flex is-vcentered">
            <Link to="/contact" className="contact-btn has-margin-right-5">
              {t("Contact us")}
              <span
                style={{ widht: 19, height: 19, padding: 5 }}
                className="icon has-margin-left-1"
              >
                <ArrowRightIcon />
              </span>
            </Link>

            {window.location.pathname.split("/")[2] === "black-friday" ? (
              <></>
            ) : (
              <a
                href="https://merchant-stage.twispay.com/auth/signup"
                rel="noopener noreferrer"
                target="_blank"
                className={`${
                  locale === "ro" ? "has-margin-right-3" : "has-margin-right-24"
                }`}
              >
                <button className="button is-blue is-rounded is-small get-started-btn">
                  {t("Get Started")}
                </button>
              </a>
            )}
            {/* <a
              href="https://merchant-stage.twispay.com/auth/signup"
              rel="noopener noreferrer"
              target="_blank"
              className={`${
                locale === "ro" ? "has-margin-right-3" : "has-margin-right-24"
              }`}
            >
              <button className="button is-blue is-rounded is-small get-started-btn">
                {t("Get Started")}
              </button>
            </a> */}

            <div
              onMouseEnter={() => setOpenedDropdown("language-switcher")}
              onMouseLeave={() => setOpenedDropdown(null)}
              onKeyPress={() => setOpenedDropdown("language-switcher")}
              role="button"
              tabIndex="0"
              className="language-switcher-wrapper"
            >
              <button className="is-flex is-vcentered">
                <span className="icon has-margin-right-3">
                  <EarthIcon />
                </span>
                {locale.toUpperCase()}
              </button>
              <AnimatePresence>
                {openedDropdown === "language-switcher" && (
                  <LanguageSwitcher key="language-switcher" />
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <ResponsiveNavbar hasLightBackground={hasLightBackground} />
      </div>
    </nav>
  )
}

export default Navbar
